import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';

import InputMask from '../../../components/InputMask';
import Select from '../../../components/Select';

interface MaterialData {
  id: string;
  created_at: string;
  name: string;
  description: string;
  thumbnail_url: string;
  file_url: string;
}

interface MaterialPreviewFormData {
  title: string;
  category: string;
  description: string | null;
  video: string;
  video_provider: string;
  duration: string;
}

const LessonPreview: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [lesson, setLesson] = useState<MaterialData>({} as MaterialData);

  const [loading, setLoading] = useState(false);

  const [thumbnailFile, setThumbnailFile] = useState<Blob | string>('');

  const { addToast } = useToast();

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const [lessonResponse] = await Promise.all([
          api.get(`/lessons/${slug}`),
        ]);

        setLesson(lessonResponse.data);

        formRef.current?.setData({
          duration: lessonResponse.data.formatted_duration,
        });

        formRef.current?.setFieldValue(
          'category',
          lessonResponse.data.category,
        );
        formRef.current?.setFieldValue(
          'video_provider',
          lessonResponse.data.video_provider,
        );

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'A aula não foi encontrada!',
        });

        history.push('/aulas');
      }
    }

    loadData();
  }, [slug, addToast, history]);

  const handleSubmit = useCallback(
    async (data: MaterialPreviewFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          category: Yup.string().required('Categoria obrigatória'),
          description: Yup.string(),
          video: Yup.string().required('Vídeo obrigatório'),
          video_provider: Yup.string().required(
            'Provedor de vídeo obrigatório',
          ),
          duration: Yup.string().required('Duração obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const [hours, minutes, seconds] = data.duration.split(':');
        const duration =
          Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description ?? '');
        formData.append('category', data.category);
        formData.append('video', data.video);
        formData.append('video_provider', data.video_provider);
        formData.append('duration', String(duration));

        if (thumbnailFile) {
          formData.append('thumbnail', thumbnailFile);
        }

        const updatedLessonResponse = await api.put<MaterialData>(
          `lessons/${lesson.id}`,
          formData,
        );

        setLesson(updatedLessonResponse.data);

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O material foi atualizado com sucesso!',
        });

        history.push('/aulas');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, lesson.id, history, thumbnailFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar aula"
          items={[
            { title: 'Aulas', link: '/aulas' },
            { title: 'Visualizar aula' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={lesson}
                noValidate
              >
                <Row>
                  <Col md={8}>
                    <Input type="text" name="title" label="Título" />
                  </Col>

                  <Col md={4}>
                    <Select
                      options={[
                        {
                          label: 'Educacione',
                          value: 'educacione',
                        },
                        {
                          label: 'Redacione',
                          value: 'redacione',
                        },
                      ]}
                      name="category"
                      label="Categoria"
                    />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="video" label="Vídeo" />
                  </Col>

                  <Col md={4}>
                    <Select
                      options={[
                        {
                          label: 'YouTube',
                          value: 'youtube',
                        },
                      ]}
                      name="video_provider"
                      label="Provedor de vídeo"
                    />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      type="text"
                      name="duration"
                      label="Duração"
                      mask="99:99:99"
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Dropzone
                      label="Thumbnail"
                      accept="image/*"
                      maxSize={31457280}
                      onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar aula
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default LessonPreview;
