import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';
import TextArea from '../../../components/TextArea';
import InputMask from '../../../components/InputMask';
import Select from '../../../components/Select';

interface LessonRegistrationFormData {
  title: string;
  category: string;
  description: string | null;
  video: string;
  video_provider: string;
  duration: string;
}

const LessonRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [thumbnailFile, setThumbnailFile] = useState<Blob | string>('');

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: LessonRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          category: Yup.string().required('Categoria obrigatória'),
          description: Yup.string(),
          video: Yup.string().required('Vídeo obrigatório'),
          video_provider: Yup.string().required(
            'Provedor de vídeo obrigatório',
          ),
          duration: Yup.string().required('Duração obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const [hours, minutes, seconds] = data.duration.split(':');
        const duration =
          Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description ?? '');
        formData.append('category', data.category);
        formData.append('video', data.video);
        formData.append('video_provider', data.video_provider);
        formData.append('duration', String(duration));

        if (thumbnailFile) {
          formData.append('thumbnail', thumbnailFile);
        }

        await api.post('/lessons', formData);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A aula foi cadastrada com sucesso!',
        });

        history.push('/aulas');
      } catch (err) {
        setLoading(false);

        console.log(err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history, thumbnailFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar aula"
          items={[
            { title: 'Aulas', link: '/aulas' },
            { title: 'Cadastrar aula' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={8}>
                    <Input type="text" name="title" label="Título" />
                  </Col>

                  <Col md={4}>
                    <Select
                      options={[
                        {
                          label: 'Educacione',
                          value: 'educacione',
                        },
                        {
                          label: 'Redacione',
                          value: 'redacione',
                        },
                      ]}
                      name="category"
                      label="Categoria"
                    />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="video" label="Vídeo" />
                  </Col>

                  <Col md={4}>
                    <Select
                      options={[
                        {
                          label: 'YouTube',
                          value: 'youtube',
                        },
                      ]}
                      name="video_provider"
                      label="Provedor de vídeo"
                    />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      type="text"
                      name="duration"
                      label="Duração"
                      mask="99:99:99"
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Dropzone
                      label="Thumbnail"
                      accept="image/*"
                      maxSize={31457280}
                      onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar aula
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default LessonRegistration;
