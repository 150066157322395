import React, { useState, useEffect, useCallback } from 'react';

import { useDropzone, FileRejection } from 'react-dropzone';
import { MdErrorOutline, MdInsertDriveFile } from 'react-icons/md';

import { Container, FileContainer, Error } from './styles';

interface DropzoneProps {
  label: string;
  accept: string | string[];
  /** Maximum file size in bytes
   * @example 1000000 = 1MB
   */
  maxSize: number;
  onDrop(file: File): void;
}

const Dropzone: React.FC<DropzoneProps> = ({
  label,
  accept,
  maxSize,
  onDrop,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewFile, setPreviewFile] = useState('');
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (selectedFile) {
      const [type] = selectedFile.type.split('/');

      if (type === 'image') {
        const reader = new FileReader();

        reader.onload = e => {
          if (e.target) {
            setPreviewFile(String(e.target.result));
          }
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }, [selectedFile]);

  const onDropAccepted = useCallback(
    (files: File[]) => {
      setSelectedFile(files[0]);
      onDrop(files[0]);
      setErrors([]);
    },
    [onDrop],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const fileRejection = fileRejections[0];
      const maxSizeInMB = maxSize / 100000;

      const fileRejectionErrors = fileRejection.errors.map(error => {
        switch (error.code) {
          case 'file-invalid-type':
            return 'O formato do arquivo é inválido';
          case 'file-too-large':
            return `O arquivo excede o tamanho máximo permitido (${maxSizeInMB}MB)`;
          case 'too-many-files':
            return 'Somente um arquivo permitido';
          default:
            return 'O arquivo é inválido';
        }
      });

      setSelectedFile(null);
      setErrors(fileRejectionErrors);
    },
    [maxSize],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept,
    maxSize,
    onDropRejected,
    onDropAccepted,
  });

  return (
    <Container hasError={!!errors.length}>
      <p>{label}</p>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        {selectedFile && (
          <FileContainer>
            {previewFile ? (
              <img src={previewFile} alt="" />
            ) : (
              <MdInsertDriveFile size={80} />
            )}
            <span>{selectedFile.name}</span>
          </FileContainer>
        )}

        {isDragActive ? (
          <p>Solte o arquivo aqui</p>
        ) : (
          <p>Arraste ou clique para adicionar o arquivo</p>
        )}
      </div>
      {!!errors.length && (
        <Error>
          {errors.map(error => (
            <li key={error}>
              <MdErrorOutline size={16} />
              {error}
            </li>
          ))}
        </Error>
      )}
    </Container>
  );
};

export default Dropzone;
